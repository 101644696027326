<template>
  <nav class="w-full flex flex-col justify-between items-center
              md:flex-row">
    <div>
      <template v-if="isAuthenticated">
        <ReportsMenu />
      </template>
    </div>
    <ul class="flex flex-col
               sm:flex-row">
      <template v-if="isAuthenticated">
        <li v-if="executiveReport && executiveReport.reportInput" class="flex justify-center">
          <Button type="text"
                  class="text-white"
                  @onClick="rerunReport">
            <template v-slot:iconLeft>
              <ReloadIcon class="text-gray-300 w-3" />
            </template>
            Rerun Report
          </Button>
        </li>
        <li class="flex justify-center">
          <Button @onClick="handleNewReport" type="primary">+ NEW REPORT</Button>
        </li>
        <li class="flex justify-center">
          <a href="mailto:orbital@phobos.io" target="_blank" class="text-gray-300">
            <Button type="text" class="text-gray-300 pr-0 h-full">
              orbital@phobos.io
            </Button>
          </a>
        </li>
        <li class="flex justify-center w-44">
          <div
            href="#"
            class="inline-block font-semibold tracking-wider text-sm relative text-center
                 appearance-none uppercase
                 focus:outline-none transition-colors duration-100 ease-in
                 bg-transparent pl-6 py-3 text-gray-300 border-gray-600 sm:border-l">
            <div class="
              flex
              items-center
            ">
              <div class="flex flex-col text-right">
                <p class="text-white">{{ currentUser.firstName }} {{ currentUser.lastName }}</p>
                <p v-if="currentUser.organization && currentUser.organization.name"
                  class="text-2xs">{{ currentUser.organization.name }}</p>
              </div>
              <Button @onClick="handleLogout" type="text" class="text-white">
              <template v-slot:iconLeft>
                <LogoutIcon class="text-gray-300 w-3" />
              </template>
                Logout</Button>
            </div>
          </div>
        </li>
      </template>
      <template v-if="!isAuthenticated">
        <li class="flex justify-center">
          <a href="mailto:orbital@phobos.io" target="_blank" class="text-gray-300">
            <Button type="text" class="text-gray-300 pr-0">
              orbital@phobos.io
            </Button>
          </a>
        </li>
        <li class="flex justify-center">
          <Button @onClick="handleLogin" type="text" class="text-gray-300">Login</Button>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import ReloadIcon from '@/assets/reload.svg?inline';
import LogoutIcon from '@/assets/logout.svg?inline';
import ReportsMenu from '@/components/ReportsMenu.vue';

export default {
  name: 'Navigation',
  components: {
    Button,
    LogoutIcon,
    ReloadIcon,
    ReportsMenu,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'executiveReport', 'currentUser']),
  },
  methods: {
    ...mapActions(['logout', 'submitNewReport', 'fetchReports']),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'login' });
      });
    },
    handleLogin() {
      this.$router.push({ name: 'login' });
    },
    handleNewReport() {
      this.$router.push({ name: 'new-report' })
        .catch(() => {});
    },
    rerunReport() {
      const { reportInput } = this.executiveReport;
      return this.submitNewReport(reportInput)
        .then((result) => {
          const newReportID = result.data.id;
          this.$router.push({
            name: 'executive-report',
            params: {
              execReport: newReportID,
            },
          });
        });
    },
  },
};
</script>
