import router from '@/router';
import api from '../api';
import constants from '../../config/constants';

const { URL } = constants;

const state = {
  idTokenPayload: null,
  userId: null,
  isAuthenticated: false,
  organizationIsInactive: false,
  currentUser: {},
  jwtToken: null,
};

const getters = {
  idTokenPayload: ({ idTokenPayload }) => idTokenPayload,
  isAuthenticated: ({ isAuthenticated }) => isAuthenticated,
  organizationIsInactive: ({ organizationIsInactive }) => organizationIsInactive,
  currentUser: ({ currentUser }) => currentUser,
  isSuperUser: ({ currentUser }) => (currentUser && currentUser.isSuperuser ? currentUser.isSuperuser : false), // eslint-disable-line
};

const mutations = {
  CLEAR_STATE: (state) => {
    state.authenticationError = '';
    state.idTokenPayload = null;
    state.userId = null;
    state.isAuthenticated = false;
  },
  CLEAR_AUTHENTICATION_ERROR: (state) => {
    state.authenticationError = '';
  },
  SET_TOKEN_PAYLOAD: (state, payload) => {
    state.idTokenPayload = payload;
  },
  SET_ISAUTHENTICATED: (state, payload) => {
    state.isAuthenticated = payload;
  },
  SET_ORGANIZATION_INACTIVE: (state, payload) => {
    state.organizationIsInactive = payload;
  },
  UPDATE_CURRENT_USER: (state, payload) => {
    state.currentUser = payload;
  },
};

const actions = {
  fetchCurrentUser: ({ commit }) => api.get('users/0/').then(({ data }) => {
    commit('UPDATE_CURRENT_USER', data);
  }),
  login: async ({ commit }, { email, password }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    try {
      const { data } = await api.post('login/', { username: email, password });
      localStorage.setItem('totp-challenge-token', data.access);
      setTimeout(async () => {
        const { data } = await api.get('is-enrolled/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('totp-challenge-token')}`,
          },
        });
        if (data.enrolled) {
          await router.push('totp-verify');
        } else {
          await router.push('totp-enroll');
        }
      }, 2500);
    } catch (error) {
      commit('UPDATE_GLOBAL_ERRORS', [error.description]);
    } finally {
      commit('UPDATE_IS_LOADING', false, { root: true });
    }
  },
  totpEnroll: async () => api.get('setup-totp/', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('totp-challenge-token')}`,
    },
  }),
  totpRecoveryTokens: async () => api.get('otp-recovery-device/', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('totp-challenge-token')}`,
    },
  }),
  verifyTotp: async (_, code) => {
    const { data } = await api.post('verify-totp/', code, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('totp-challenge-token')}`,
      },
    });
    localStorage.setItem('orbital-access-token', data.token);
  },
  logout: ({ commit }) => {
    commit('CLEAR_STATE');
    localStorage.clear();

    return new Promise((resolve) => resolve());
  },

  checkAuthentication: ({ commit }) => {
    const isAuthenticated = Boolean(localStorage.getItem('orbital-access-token'));
    commit('SET_ISAUTHENTICATED', isAuthenticated);
  },

  register: async ({ commit, dispatch }, registrationRequest) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    try {
      const { data } = await api.post('registration/', registrationRequest);
      if (data && data.statusCode && data.statusCode !== 200) {
        if (data.message && data.message.includes('PasswordStrengthError')) {
          commit('UPDATE_GLOBAL_ERRORS', ['Your password is too weak.']);
        }
        commit('UPDATE_GLOBAL_ERRORS', [
          'Your registration code is no longer valid, please reach out to Orbital support.',
        ]);
      }
      dispatch('login', {
        email: registrationRequest.emailAddress,
        password: registrationRequest.password,
      });
    } catch (err) {
      if (
        err.response.data
        && err.response.data.nonFieldErrors
        && err.response.data.nonFieldErrors.includes('password and password_confirmation must match')
      ) {
        commit('UPDATE_GLOBAL_ERRORS', ['Your passwords must match!']);
      } else {
        commit('UPDATE_GLOBAL_ERRORS', [
          'Your registration code is no longer valid, please reach out to Orbital support.',
        ]);
      }
    } finally {
      commit('UPDATE_IS_LOADING', false, { root: true });
    }
  },

  requestForgotPassword: async ({ commit }, email) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    try {
      await api.post('request-password-change/', { email, url: URL });
    } catch (error) {
      commit('UPDATE_GLOBAL_ERRORS', [error]);
    } finally {
      commit('UPDATE_IS_LOADING', false, { root: true });
    }
  },

  updatePassword: async ({ commit }, password) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    try {
      await api.post('update-password/', { password }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('totp-challenge-token')}`,
        },
      });
    } catch (error) {
      commit('UPDATE_GLOBAL_ERRORS', [error]);
    } finally {
      commit('UPDATE_IS_LOADING', false, { root: true });
    }
  },

  validateOrganization: ({ commit, dispatch }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });

    // reports endpoint will return 401 if org is disabled
    return api
      .get(`${URL}reports/`)
      .then(() => {
        commit('SET_ORGANIZATION_INACTIVE', false);
        dispatch('checkAuthentication');
      })
      .catch(() => {
        commit('SET_ORGANIZATION_INACTIVE', true);
        router.push('/inactive-organization');
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
      });
  },

  signup: ({ commit, dispatch }, signupRequest) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post('organizations/', signupRequest)
      .then(() => dispatch('login', {
        email: signupRequest.adminEmail,
        password: signupRequest.adminPassword,
      }))
      .catch((err) => {
        if (err.response && err.response.data) commit('UPDATE_FIELD_ERRORS', err.response.data);
        commit('UPDATE_GLOBAL_ERRORS', [
          'There was an error creating your organization. Please check all fields and try again.',
        ]);
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
      });
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
