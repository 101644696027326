import Vue from 'vue';
import VueRouter from 'vue-router';
import authenticated from '@/guards/authenticated';
import softAuth from '@/guards/soft-auth';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    beforeEnter: (to, from, next) => softAuth(to, from, next),
    path: '/totp-enroll',
    name: 'totp-enroll',
    component: () => import(/* webpackChunkName: "login" */ '../views/TotpEnroll.vue'),
  },
  {
    beforeEnter: (to, from, next) => softAuth(to, from, next),
    path: '/totp-verify',
    name: 'totp-verify',
    component: () => import(/* webpackChunkName: "login" */ '../views/TotpVerify.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "password" */ '../views/ForgotPassword.vue'),
  },
  {
    beforeEnter: (to, from, next) => softAuth(to, from, next),
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "password" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/registration',
    name: 'register',
    component: () => import(/* webpackChunkName: "password" */ '../views/Register.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
  },
  {
    beforeEnter: (to, from, next) => authenticated(to, from, next),
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "report" */ '../views/Report/Index.vue'),
    children: [
      {
        component: () => import(/* webpackChunkName: "report" */ '../views/Report/ExecutiveReport.vue'),
        name: 'executive-report',
        path: ':execReport',
      },
      {
        component: () => import(/* webpackChunkName: "report" */ '../views/Report/ExecutiveReport_Old.vue'),
        name: 'old-executive-report',
        path: 'old/:execReport',
      },
      {
        component: () => import(/* webpackChunkName: "report" */ '../views/Report/DomainReport.vue'),
        name: 'domain-report',
        path: 'exec/:execReport/domain/:domainReport',
      },
      {
        component: () => import(/* webpackChunkName: "report" */ '../views/Report/NewReport.vue'),
        name: 'new-report',
        path: '/new-report',
      },
    ],
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import(/* webpackChunkName: "password" */ '../views/Callback.vue'),
  },
  {
    path: '/inactive-organization',
    name: 'inactive-organization',
    component: () => import(/* webpackChunkName: "password" */ '../views/InactiveOrganization.vue'),
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: () => import(/* webpackChunkName: "sandbox" */ '../views/Sandbox.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
